import React from 'react'
import './Socials.css'
import { Bounce } from 'react-reveal'

//Social Media Images
import linkedin from '../../images/social/linkedin.png'
import github from '../../images/social/github.png'

const Socials = () => {
  return (
    <Bounce cascade>
      <div className="links">
        <a
          href="https://github.com/MartynFitzgerald"
          target="_blank"
            rel="noopener noreferrer"
        >
          <img src={github} alt="Github Logo" width="40px" />
        </a>
        <a
          href="https://www.linkedin.com/in/martyn-fitzgerald-689074167/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={linkedin} alt="Linkedin Logo" width="40px" />
        </a>
      </div>
    </Bounce>
  )
}

export default Socials
